import React from 'react';
import * as styles from './index.module.scss';
import classNames from 'classnames';
import { useLocale } from '../../../services/i18n/useLocale';
import { newJapanGetText } from '../getText';

export default function JapanGamme() {
  const intl = useLocale();
  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.title}>
          <h2>{newJapanGetText(intl.locale, 'section3')}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: newJapanGetText(intl.locale, 'section3Description'),
            }}
          />
        </div>
        <div className={styles.visual}>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet="/assets/new-japan/gamme.webp"
            />
            <img
              src="/assets/new-japan/gamme-mobile.webp"
              loading="lazy"
              alt="Gamme de produits"
              // className={styles.image}
            />
          </picture>
        </div>
      </div>
    </div>
  );
}
