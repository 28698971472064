import React from 'react';
import * as styles from './index.module.scss';
import { newJapanGetText } from '../getText';
import { useLocale } from '../../../services/i18n/useLocale';

export default function JapanCollection() {
  const intl = useLocale();
  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className={styles.title}>
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet="/assets/new-japan/logo.svg"
            />
            <source
              media="(max-width: 767px)"
              srcSet="/assets/new-japan/logo-mobile.webp"
            />
            <img src="/assets/new-japan/logo.svg" alt="logo" />
          </picture>
        </div>

        <picture className={styles.pictureWrapper}>
          <source
            media="(min-width: 768px)"
            srcSet="/assets/new-japan/collection.webp"
          />
          <source
            media="(max-width: 767px)"
            srcSet="/assets/new-japan/collection-mobile.webp"
          />
          <img
            src="/assets/new-japan/collection.webp"
            alt="collection"
            className={styles.imageCollection}
          />
        </picture>

        <div className={styles.baseline}>
          <span className={styles.redDot} />
          <h2>{newJapanGetText(intl.locale, 'section5')}</h2>
        </div>

        <p className={styles.legend}>
          {newJapanGetText(intl.locale, 'disclaimer')}
        </p>
      </div>
    </div>
  );
}
