import React from 'react';
import classNames from 'classnames';
import * as styles from './index.module.scss';
import useIntersection from '../../../utils/useIntersection';
import { newJapanGetText } from '../getText';
import { useLocale } from '../../../services/i18n/useLocale';

export default function JapanCulture() {
  const intl = useLocale();
  const ref = React.useRef(null);
  const videoRef = React.useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  });

  const isVisible = !!intersection?.isIntersecting;

  React.useEffect(() => {
    if (!videoRef.current) return;
    if (isVisible) {
      videoRef.current.play().catch((err) => {
        console.log('Failed to play video:', err);
      });
    } else {
      videoRef.current.pause();
    }
  }, [isVisible]);

  return (
    <div className={styles.wrapper} ref={ref}>
      <div className={classNames('container')}>
        <div className={styles.title}>
          <h2>{newJapanGetText(intl.locale, 'section2')}</h2>
        </div>
      </div>
      <div className={styles.videoWrapper}>
        <video
          ref={videoRef}
          src="https://player.vimeo.com/progressive_redirect/playback/1055265751/rendition/720p/file.mp4?loc=external&signature=a298eac4e59af9f3b86a3b8cf6754809666fb1e962bf7c70d0f33e244cc0a890"
          muted
          loop
          playsInline
          loading="lazy"
        />
      </div>
    </div>
  );
}
