// extracted by mini-css-extract-plugin
export var box = "index-module--box--zmMb7";
export var decoration = "index-module--decoration--fWFjA";
export var firstVideo = "index-module--firstVideo--7W5j7";
export var grid = "index-module--grid--7K53P";
export var gridContainer = "index-module--gridContainer--0kOV+";
export var number1 = "index-module--number1--lh56F";
export var number2 = "index-module--number2--zSa2J";
export var number3 = "index-module--number3--GWfuR";
export var product = "index-module--product--4B3ZM";
export var row1 = "index-module--row1--75kO7";
export var row2 = "index-module--row2--LljGB";
export var secondVideo = "index-module--secondVideo--N7BPI";
export var splatter = "index-module--splatter--CWG8s";
export var title = "index-module--title--TrKc0";
export var wrapper = "index-module--wrapper--G1Ihr";