import React from 'react';
import * as styles from './index.module.scss';
import classNames from 'classnames';
import { useIntersection } from 'react-use';
import { useLocale } from '../../../services/i18n/useLocale';
import { newJapanGetText } from '../getText';

export default function JapanDesign() {
  const intl = useLocale();
  const ref = React.useRef(null);
  const videoRef = React.useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  });

  const isVisible = !!intersection?.isIntersecting;

  React.useEffect(() => {
    if (!videoRef.current) return;
    if (isVisible) {
      videoRef.current.play().catch((err) => {
        console.log('Failed to play video:', err);
      });
    } else {
      videoRef.current.pause();
    }
  }, [isVisible]);

  return (
    <>
      <div className={styles.wrapper} ref={ref}>
        <div className={classNames(styles.container, 'container')}>
          <div className={styles.title}>
            <h2>{newJapanGetText(intl.locale, 'section1')}</h2>
          </div>
          <div className={styles.visual}>
            <video
              ref={videoRef}
              src="https://player.vimeo.com/progressive_redirect/playback/1055243056/rendition/360p/file.mp4?loc=external&signature=ce8537bf5a7daca55b430e3a8bebcc31b84541039844fd262d9d15038cb875ff"
              muted
              loop
              playsInline
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div className={classNames(styles.productsContainer, 'container')}>
        <div className={styles.productAnchor}>
          <img
            className={styles.product}
            src="/assets/new-japan/design-product.webp"
            alt="design"
          />
        </div>
      </div>
    </>
  );
}
