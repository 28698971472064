import React from 'react';
import * as styles from './new.module.scss';
import JapanHero from '../components/new-japan/hero';
import JapanDesign from '../components/new-japan/design';
import JapanCulture from '../components/new-japan/culture';
import JapanGamme from '../components/new-japan/gamme';
import JapanSerie from '../components/new-japan/serie';
import JapanCollection from '../components/new-japan/collection';
import Seo from '../components/seo';

const Page = () => {
  return (
    <div className={styles.wrapper}>
      <Seo title="Nouvelle collection" />

      <JapanHero />
      <JapanDesign />
      <JapanCulture />
      <JapanGamme />
      <JapanSerie />
      <JapanCollection />
    </div>
  );
};

export default Page;
