import React from 'react';
import * as styles from './index.module.scss';
import classNames from 'classnames';
import useIntersection from '../../../utils/useIntersection';
import { useLocale } from '../../../services/i18n/useLocale';
import { newJapanGetText } from '../getText';

export default function JapanSerie() {
  const intl = useLocale();
  const ref = React.useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  });

  const isVisible = !!intersection?.isIntersecting;

  const videoRef = React.useRef(null);

  React.useEffect(() => {
    if (!videoRef.current) return;
    if (isVisible) {
      videoRef.current.play().catch((err) => {
        console.log('Failed to play video:', err);
      });
    } else {
      videoRef.current.pause();
    }
  }, [isVisible]);

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.splatter, 'container')} />
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.visual} ref={ref}>
          <video
            ref={videoRef}
            src="https://player.vimeo.com/progressive_redirect/playback/1055583742/rendition/540p/file.mp4?loc=external&signature=fba662c31d8b4447c59f3f5637693e086fdc68e8b3c9583a52a10fa51c1d3a85"
            muted
            loop
            playsInline
            loading="lazy"
          />
        </div>
        <div className={styles.title}>
          <h2
            dangerouslySetInnerHTML={{
              __html: newJapanGetText(intl.locale, 'section4'),
            }}
          />
        </div>
      </div>
    </div>
  );
}
